import {React, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Carousel, Row, Typography, Col, Card } from "antd";

import "./index.css";

const { Title, Paragraph } = Typography;

const HomePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);


  return (
    <div className="container">
      <Carousel autoplay style={{height: '50%', overflow: 'hidden'}}>
          <div>
              <div style={{backgroundImage: "url('/img2.jpg') ",  backgroundSize: 'cover', overflow: 'hidden'}}>
                      <Row justify="center" style={{height: '500px', alignItems: 'center', display: "flex"}}>
                        <Col span={24} align="middle">
                            <Title level={1} style={{color: "white"}}>珠海横琴华青科技有限公司</Title>
                            <Paragraph style={{fontSize: "22px", color: "white"}}>
                                利用自然语言处理技术赋能，提高企业生产力，提升用户交互体验。
                            </Paragraph>
                            <Link to="/about">
                                <Button type="primary" size="large">
                                    了解更多
                                </Button>
                             </Link>
                        </Col>
                    </Row>
                  </div>
          </div>
      </Carousel>
        <Row justify="center" className="home-row row-background-white">
            <Col span={24} className="title">
                <Title level={2}>核心技术</Title>
            </Col>
            <Col   align="middle" xs={24} sm={12} md={8}>
                <img className="card-icon" src="AI.png" alt="人工智能"/>
                <h2 className="home-title">人工智能</h2>
                <p className="card-text">基于大数据技术以及计算资源，人工智能技术可以自动化处理复杂任务、提高生产效率、优化商业流程。应用场景广泛，涵盖医疗、金融、零售等各行各业，包括智能客服、预测分析、自动化生产等。</p>
            </Col>
            <Col  xs={24} sm={12} md={8} align="middle">
                <img className="card-icon" src="NLP.png" alt="自然语言处理"/>
                <h2 className="home-title">自然语言处理</h2>
                <p className="card-text">自然语言处理技术利用计算机对人类语言进行理解、生成、翻译等处理，能够帮助客户快速识别问题、回答问题、生成语言内容，提高客户满意度和服务效率，适用于客服、知识库、问答系统等多种业务场景。</p>
            </Col>
            <Col xs={24} sm={12} md={8} align="middle">
                <img className="card-icon" src="chatbot.png" alt="人机交互"/>
                <h2 className="home-title">人机交互</h2>
                <p className="card-text">结合自然语言处理、人工智能等技术，人机交互技术可提供更智能、自然的交互方式。在企业场景中，可用于智能客服、自动化客户服务等方面，提高服务效率，降低成本；而在面向C端的产品中，则可通过更自然、直观的交互方式，提升产品的易用性和用户满意度。</p>
            </Col>
        </Row>
        <Row justify="center" className="home-row row-background-gray" id="service">
            <Col span={24} className="title">
                <Title level={2}>产品服务</Title>
            </Col>

            <Row gutter={[24, 16]} justify="space-around" align="middle">
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Link to="/service/assistant">
                        <Card hoverable>
                            <Row justify="space-around" align="middle">
                                <Col xs={24} sm={24} md={16} lg={24} style={{paddingLeft: "2%", paddingRight: "5%", marginBottom: "5px"}}>
                                    <Title underline={true} level={3} style={{ color: "#0088ff"}}>AI智能助手</Title>
                                    <Paragraph className="text">我们的宗旨之一是通过简洁、易用的交互方式，为广大用户提供高质量服务。AI智能助手模块提供多个高质量的应用，利用人工智能、NLP技术，为用户提供日常服务。
                                        面对企业，可根据场景进行定制化开发，针对性地进行赋能。</Paragraph>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={24} >
                                    <img src="/home/assistant.jpg" alt="智能助手" className="home-img"/>
                                </Col>
                            </Row>
                        </Card>
                    </Link>
                </Col>

                <Col  xs={24} sm={24} md={24} lg={16}>
                    <Row style={{marginBottom: "20px"}}>
                        <Link to="/solution/chatbot">
                            <Card hoverable>
                                <Row justify="space-around" align="middle">
                                    <Col xs={24} sm={24} md={16} style={{paddingLeft: "2%", paddingRight: "5%", marginBottom: "5px"}}>
                                        <Title underline={true} level={3} style={{ color: "#0088ff"}}>智能对话系统</Title>
                                        <Paragraph className="text">基于自研的智能对话系统框架，结合自然语言理解、对话管理、语音识别、机器翻译等技术，提供多语言、多领域智能对话系统的定制和部署服务。
                            系统支持云服务和私有化部署，提供可视化对话分析，帮助用户快速定位问题，提升对话、服务质量。</Paragraph>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} align="middle">
                                       <img src="/home/chatbot.png" alt="智能对话系统" className="home-img"/>
                                    </Col>
                                </Row>
                            </Card>
                        </Link>
                    </Row>
                    <Row>
                        <Link to="/service/media">
                            <Card hoverable>
                                <Row justify="space-around" align="middle">
                                    <Col xs={24} sm={24} md={16} style={{paddingLeft: "2%", paddingRight: "5%", marginBottom: "5px"}}>
                                        <Title underline={true} level={3} style={{ color: "#0088ff"}}>视频文字处理</Title>
                                        <Paragraph className="text">利用自然语言处理和计算机视觉技术，自动提取视频字幕，并实现多语言语音识别和文本翻译。可提供多语言字幕添加、视频剪辑等服务。帮助用户高效处理视频、音频资料，提高工作效率和业务水平。</Paragraph>
                                    </Col>
                                    <Col xs={24} sm={24} md={8} align="middle">
                                      <img src="/home/video_process.jpg" alt="视频文字处理" className="home-img"/>
                                    </Col>
                                </Row>
                            </Card>
                        </Link>
                    </Row>
                </Col>
            </Row>
        </Row>
        <Row justify="center" className="home-row row-background-white">
            <Col span={24} className="title">
                <Title level={2}>解决方案</Title>
            </Col>
            <Col xs={24} sm={12} md={12} style={{paddingRight: "10px"}}>
                <Link to="/solution/chatbot">
                    <Card hoverable={true} style={{marginBottom: "10px", width: '80%', height: 150}}>
                        <Col span={18}>
                            <Title level={4}>对话机器人</Title>
                            <Paragraph></Paragraph>
                        </Col>
                        <Col span={8}>
                            <img/>
                        </Col>
                    </Card>
                </Link>
                <Link to="/solution/develop">
                    <Card hoverable={true} style={{width: '80%', height: 150}}>
                        <Col span={18}>
                            <Title level={4}>定制化开发</Title>
                            <Paragraph></Paragraph>
                        </Col>
                        <Col span={8}>
                            <img/>
                        </Col>
                    </Card>
                </Link>
            </Col>
            <Col xs={24} sm={12} md={12}>
                <Link to="/solution/deploy">
                    <Card hoverable={true} style={{marginBottom: "10px", width: '80%', height: 150}}>
                        <Col span={18}>
                            <Title level={4}>私有化部署</Title>
                            <Paragraph></Paragraph>
                        </Col>
                        <Col span={8}>
                            <img/>
                        </Col>
                    </Card>
                </Link>
                <Link to="/solution/deploy">
                    <Card hoverable={true} style={{width: '80%', height: 150}}>
                        <Col span={18}>
                            <Title level={4}>云服务</Title>
                            <Paragraph></Paragraph>
                        </Col>
                        <Col span={8}>
                            <img/>
                        </Col>
                    </Card>
                </Link>
            </Col>
        </Row>

        <Row  justify="center" className="home-row row-background-gray" style={{paddingLeft: '10%', paddingRight: '10%'}}>
            <Col span={24} >
                <Title level={2} className="title">服务优势</Title>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} align="middle" className="col" style={{ display: 'flex', justifyContent: 'center'}}>
                <Card className="card">
                    <img className="card-icon" src="technology.png" alt="前沿技术"/>
                    <Title level={3}>前沿技术</Title>
                    <Paragraph className="card-text">持续跟随和掌握最新的前沿技术，不断学习和研究新技术，并将其应用到我们的业务中，以提供最先进、最优秀的解决方案。</Paragraph>
                </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} align="middle" className="col" style={{ display: 'flex', justifyContent: 'center'}}>
                <Card className="card">
                    <img className="card-icon" src="personal.png" alt="量身定制"/>
                    <Title level={3}>量身打造</Title>
                    <Paragraph className="card-text">基于客户的场景需求及数据，个性化定制智能对话、语音、翻译等模型，更加贴合客服实际业务。</Paragraph>
                </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} align="middle" className="col" style={{ display: 'flex', justifyContent: 'center'}}>
                <Card className="card">
                    <img className="card-icon" src="deploy.png" alt="灵活部署"/>
                    <Title level={3}>灵活部署</Title>
                    <Paragraph className="card-text">提供云部署及私有化部署方式，保证用户数据的安全，满足不同场景的需求。</Paragraph>
                </Card>
            </Col>
        </Row>
    </div>
  );
};

export default HomePage;
