import {useState} from "react";
import { Tabs, Row, Col, Card } from "antd";
import Login from "../../../components/Login";
import ScanLogin from "../../../components/ScanLogin";

import "./index.css";

const { TabPane } = Tabs;

export default function LoginPage() {
    const [isRegister, setIsRegister] = useState(false)
    const [isForgetpd, setIsForgetpd] = useState(false);

    const handleTabClick = (key) => {
        setIsRegister(false);
        setIsForgetpd(false);
      };

    return (
      <Row className="login-row">
          <Col align="middle" span={24} >
              <Card className="login-container">
                  <Tabs defaultActiveKey="login" centered onTabClick={handleTabClick}>
                      <TabPane tab="登录" key="login">
                        <Login isRegister={isRegister} isForgetpd={isForgetpd} setIsRegister={setIsRegister} setIsForgetpd={setIsForgetpd}/>
                      </TabPane>
                      <TabPane disabled tab="扫码登录" key="scanlogin">
                        <ScanLogin />
                      </TabPane>
                    </Tabs>
              </Card>
          </Col>
      </Row>
  );
}
