import React, {useState, useEffect} from 'react';
import { Button, Row, Typography, Col, Modal, Form, Input, message, Steps  } from "antd";
import axios from "axios";

import "./develop.css"

const { Step } = Steps;
const { Title, Paragraph } = Typography;


const DevelopSolutionPage = () => {
    const [visible, setVisible] = useState(false);

    const handleButtonClick = () => {
        setVisible(true);
    };

    const onFinish = (values) => {
    const params = {
        "function": "定制化咨询",
        ...values
    };

    axios.post("/huaqing_back/consult", params).then((response)=>{
        const data = response.data;
        if (data.success) {
            message.success(data.message);
        } else {
            message.error(data.message);
        }
    })
    setVisible(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
        <Modal
          title="定制化开发咨询"
          visible={visible}
          onCancel={() => setVisible(false)}
          footer={null}
        >
          <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
            >
              <Form.Item
                label="姓名"
                name="name"
                rules={[{ required: true, message: '请输入您的姓名！' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="微信"
                name="wechat"
                rules={[{ required: true, message: '请输入您的微信号！' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="邮箱"
                name="wechat"
                rules={[{ required: true, message: '请输入您的邮箱！' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="定制需求"
                name="experience"
                rules={[
                  { required: true, message: '请输入您的定制需求！' },
                  { max: 100, message: '只需要简单输入要点，最好不要超过100个字哦！' },
                ]}
              >
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  提交
                </Button>
              </Form.Item>
            </Form>
        </Modal>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "flex-start", height: '350px', paddingLeft: "15%", overflow: 'hidden', backgroundImage: "url(/develop-poster.jpg) ",  backgroundSize: 'cover'}}>
                <Row justify="start">
                    <Col span={24} align="left">
                        <Title level={2} style={{color: "white"}}>定制化功能开发</Title>
                        <Paragraph style={{fontSize: "18px", color: "white"}}>
                            基于人工智能和NLP技术，提供多领域、多场景的智能化解决方案。
                        </Paragraph>
                    </Col>
                    <Col span={24} align="left">
                        <Button type="primary" size="large" onClick={handleButtonClick}>
                            定制咨询
                        </Button>
                    </Col>
                </Row>
        </div>
        <Row gutter={[40, 48]} justify="center" className="row row-background-white" >
            <Col span={24}>
                <Title level={2} className="title" level={2}>多领域覆盖</Title>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} align="middle">
                <img src="/custom_service.jpg" alt="智能客服" className="card-img"/>
                <Title level={4} className="card-title">智能客服</Title>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} align="middle">
                <img src="/medical.jpg" alt="医疗健康" className="card-img"/>
                <Title level={4} className="card-title">医疗健康</Title>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} align="middle">
                <img src="/economic.jpg" alt="金融服务" className="card-img"/>
                <Title level={4} className="card-title">金融服务</Title>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} align="middle">
                <img src="/travel.jpeg" alt="酒店旅游" className="card-img"/>
                <Title level={4} className="card-title">酒店旅游</Title>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} align="middle">
                <img src="/living.jpeg" alt="智能家居" className="card-img"/>
                <Title level={4} className="card-title">智能家居</Title>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} align="middle">
                <img src="/education.jpg" alt="教育培训" className="card-img"/>
                <Title level={4} className="card-title">教育培训</Title>
            </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center" className="row row-background-gray" >
            <Col span={24}>
                <Title className="title" level={2}>定制化开发流程</Title>
            </Col>
            <Col span={24} className="col">
                <Steps >
                  <Step status="process " title="步骤1" description="了解需求" icon={<img src="/demand.png" alt="demand" className="icon-img"/>}/>
                  <Step status="process " title="步骤2" description="分析场景，整理、清洗数据" icon={<img src="/clean-data.png" alt="clean" className="icon-img"/>}/>
                  <Step status="process " title="步骤3" description="模型训练" icon={<img src="/train-model.png" alt="train" className="icon-img"/>} />
                  <Step status="process " title="步骤4" description="内部测试及调优" icon={<img src="/optimize.png" alt="optimize" className="icon-img"/>} />
                    <Step status="process " title="步骤5" description="模型部署" icon={<img src="/deploy-model.png" alt="deploy" className="icon-img"/>} />
                    <Step status="process " title="步骤6" description="基于用户反馈调优" icon={<img src="/feedback.png" alt="feedback" className="icon-img"/>} />
                </Steps>
            </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center" className="row row-background-white" >
            <Col span={24}>
                <Title className="title" level={2}>案例演示</Title>
            </Col>
            <Col span={24} align="center">
                  <video src="" style={{ width: '60%', height: 'auto' }} controls />
            </Col>
        </Row>
    </div>
  );
};

export default DevelopSolutionPage;
