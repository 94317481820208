import { useState, useRef, useEffect, useContext } from 'react';
import { Input, Button, Col } from 'antd';
import axios from "axios";

import { CopyOutlined, CheckOutlined, SendOutlined, LoadingOutlined} from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import checkLogin from "../CheckLogin";
import './index.css'; // 导入样式文件
import { ChatBoxContext } from '../../pages/ServicePages/AssistantPage';

const { TextArea } = Input;

function ChatBox() {
  const openQues = {
    "general": "您好，请问有什么可以帮您？",
    "文案创作": "您好，请问您想如何创作什么内容的文案？请尽可能详细地告诉我哦！",
    "文案改写": "您好，请告诉我您想改写的文案以及尽可能详细的改写需求哦！",
    "营养师": "您好，请问您需要什么营养健康的服务？",
    "心理咨询": "您好，我是您的心理咨询师，有什么话都可以和我说噢，我保证不告诉别人~"
  };
  const { chatBoxRole, setChatBoxRole } = useContext(ChatBoxContext);
  const [messages, setMessages] = useState([
    {id: 1, type: "system", text: openQues[chatBoxRole]},
  ]);
  const [inputValue, setInputValue] = useState('');

  const chatBoxRef = useRef(null);
  const chatHistory = useRef([]);
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleCopy = (messageId) => {
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      updatedMessages[messageId-1].copy = true
      return updatedMessages
    });
    setTimeout(() => {
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        updatedMessages[messageId-1].copy = false
        return updatedMessages
      });
    }, 2000);
  };

  async function handleSendMessage (event) {
    if (event.key === 'Enter' && messages[messages.length - 1].loading) {
      return;
    }

    if (inputValue.trim().replace(/\n/g, "") === '') {
      if (messages.length === 0 || messages[messages.length-1].type === 'empty_alert') {
        return;
      } else {
        const newMessage = { id: messages.length + 1, type: 'empty_alert', text: "请输入您想问的问题哦！"};
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setInputValue(prevInputValue => '');
      }
    } else {
      const newMessage = { id: messages.length + 1, type: 'user', text: inputValue};
      const userInput = inputValue;
      const LoginStatus = checkLogin();
      chatHistory.current.push({"role": "user", "content": userInput})
      setMessages(prevMessage => [...prevMessage, newMessage]);
      setInputValue(prevInputValue => '');

      if (!LoginStatus) {
        const newResponse = {
          id: messages.length + 1,
          type: 'reply',
          text: "抱歉哦，您还没有登录，请先点击右上角登录后进行体验！",
          imgUrl: "/avatar.png",
          loading: false,
          copy: false
        };
        setMessages(prevMessage => [...prevMessage, newResponse]);
      } else {
        const newResponse = {
          id: messages.length + 1,
          type: 'reply',
          text: "",
          imgUrl: "/avatar.png",
          loading: true,
          copy: false
        };
        setMessages(prevMessage => [...prevMessage, newResponse]);

        // setTimeout(() => {
        //   setMessages(prevMessages => {
        //     const updatedMessages = [...prevMessages];
        //     updatedMessages[updatedMessages.length - 1].text = "你好".repeat(200);
        //     updatedMessages[updatedMessages.length - 1].loading = false;
        //     return updatedMessages;
        //   });
        // }, 1000);
        axios({
          method: "post",
          url: '/huaqing_back/chat',
          data: {
            type: "text",
            msg: chatHistory.current.slice(-5),
            role: chatBoxRole
          },
        }).then(function (response) {
          setMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
            updatedMessages[updatedMessages.length - 1].text = response.data.response[0].text;
            chatHistory.current.push({"role": "assistant", "content": response.data.response[0].text})
            updatedMessages[updatedMessages.length - 1].loading = false;
            return updatedMessages;
          });
        }).catch(function (error) {
          console.log(error);
          setMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
            updatedMessages[updatedMessages.length - 1].text = "抱歉哦，服务器正在维护中~";
            updatedMessages[updatedMessages.length - 1].loading = false;
            return updatedMessages;
          });
        })
      }
    }
  };

  useEffect(() => {
    // 滚动到底部
    chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    setInputValue("")
  }, [messages]);

  useEffect(() => {
    chatHistory.current = [];
    setMessages(() => [
      {id: 1, type: "system", text: openQues[chatBoxRole]},
    ]);
  }, [chatBoxRole]);


  return (
    <div className="chat-box-container">
      <div className="chat-box" ref={chatBoxRef}>
        {messages.map((message) => (
          <Col span={24} align={`${message.type === 'user' ? 'end' : 'start'}`}>
            {
              message.type === 'user' ? (
                  <div className="message-container" style={{justifyContent: "end"}}>
                      <div key={message.id} className='message user'>
                          {message.text}
                       </div>
                    <img className="avatar-user" src="/avatar.png" alt="avatar"/>
                  </div>
              ) : (
                  <div className="message-container" key={message.id}>
                    <img className="avatar-reply" src="/avatar.png" alt="avatar"/>
                    <div className='message'>
                      {message.loading && <LoadingOutlined/>}
                      {message.text}
                   </div>
                    {
                      !message.loading && (
                        <CopyToClipboard text={message.text}>
                          <Button onClick={() => handleCopy(message.id)} type="link" icon={message.copy ? <CheckOutlined /> : <CopyOutlined/> }>{message.copy && "copied!"}</Button>
                        </CopyToClipboard>
                      )
                    }
                  </div>
              )
            }
          </Col>
        ))}
      </div>
      <div className="input-container">
        <TextArea className="input"  autoSize value={inputValue} allowClear={true} onPressEnter={handleSendMessage} maxLength={1500} onChange={handleInputChange} placeholder="Type your message" />
        <Button disabled={messages[messages.length-1].loading} className="send-button" onClick={handleSendMessage} icon={<SendOutlined/>}>发送</Button>
      </div>
    </div>
  );
}

export default ChatBox;
