import React, { useState, createContext, useEffect } from "react";
import { Input, Button, Row, Col, Select } from "antd";

import ChatBox from "../../../components/ChatBox"

import "./index.css"

export const ChatBoxContext = createContext();
const { Option } = Select;

const AssistantPage = () => {
    const [chatBoxRole, setChatBoxRole] = useState('general');

    const handleRoleChange = (value) => {
        setChatBoxRole(() => value);
      }

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
    <div className="container">
        <Row className="assistant-row">
            <Col span={24} className="role-col">
                <Select
                  showSearch
                  defaultValue={chatBoxRole}
                  style={{ width: 200 }}
                  placeholder="请选择智能助手角色"
                  optionFilterProp="children"
                  onChange={handleRoleChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="general">通用</Option>
                    <Option value="文案创作">文案创作</Option>
                    <Option value="文案改写">文案改写</Option>
                  <Option value="营养师">营养师</Option>
                  <Option value="心理咨询">心理辅导师</Option>
                </Select>
            </Col>
            <Col span={24}>
                <ChatBoxContext.Provider value={{ chatBoxRole, setChatBoxRole }}>
                    <ChatBox />
                </ChatBoxContext.Provider>
            </Col>
        </Row>
    </div>
  );
};

export default AssistantPage;