import React, { useState, useEffect } from 'react';
import { Menu, Layout, Dropdown, Button, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';

import checkLogin from "../CheckLogin";
import Logout from "../Logout"
import './index.css';

const { Header } = Layout;
const { Title, Paragraph } = Typography;

const Navbar = () => {
  const [top, setTop] = useState(true);
  const [menuOpenKeys, setMenuOpenKeys] = useState([]);
  const navigate = useNavigate();
  const [nickName, setNickName] = useState("")
  const [isLogin, setIsLogin] = useState(false);

  const handleLogout = () => {
    setIsLogin(false);
    Logout();
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setTop(false);
      } else {
        setTop(true);
      }
    };
    window.addEventListener('scroll', handleScroll);

    const LoginStatus = checkLogin();
    const savedAutoLogin = localStorage.getItem('autoLogin') === 'true';
    if (LoginStatus) {
      setIsLogin(LoginStatus);
      if (savedAutoLogin) {
        const nickname = localStorage.getItem("nickname");
        setNickName(nickname);
      } else {
         const nickname = sessionStorage.getItem("nickname");
         setNickName(nickname);
      }
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const LoginStatus = sessionStorage.getItem("LoginStatus") === "true";
    const nickname = sessionStorage.getItem("nickname");
      if (LoginStatus) {
        setIsLogin(LoginStatus);
        setNickName(nickname);
      }
  }, [sessionStorage.getItem("LoginStatus")])

  useEffect(() => {
    const loginExpiresAt = localStorage.getItem('loginExpiresAt');
      const LoginStatus = localStorage.getItem("LoginStatus") === "true";
      const nickname = localStorage.getItem("nickname");
      if (LoginStatus && Date.now() < loginExpiresAt) {
        setIsLogin(LoginStatus);
        setNickName(nickname);
      } else {
        localStorage.removeItem("LoginStatus");
        localStorage.removeItem('userId');
        localStorage.removeItem('nickname');
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        localStorage.removeItem('autoLogin');
        localStorage.removeItem('loginExpiresAt');
      }
  }, [localStorage.getItem("LoginStatus")])

  const handleMenuOpenChange = (keys) => {
    setMenuOpenKeys(keys);
  };

  const menuItems = [
      {
      key: 'service',
      text: '产品服务',
      subMenu: [
        {
          key: 'dialog',
          text: '智能对话系统',
          link: '/solution/chatbot',
        },
        {
          key: 'assistant',
          text: 'AI智能助手',
          link: '/service/assistant',
        },
          {
          key: 'multimedia',
          text: '视频文字处理',
          link: '/service/media',
        },
      ],
    },
    {
      key: 'solution',
      text: '解决方案',
      subMenu: [
        {
          key: 'chatbot',
          text: '对话机器人',
          link: '/solution/chatbot',
        },
        {
          key: 'develop',
          text: '定制化开发',
          link: '/solution/develop',
        },
          {
          key: 'deploy',
          text: '多样化部署',
          link: '/solution/deploy',
        },
      ],
    },
    {
      key: 'about',
      text: '关于我们',
      link: '/about',
    },
  ];

  const userMenu = [
    {
      key: "userInfo",
      text: "个人信息",
      link: "/user/userinfo",
    },
    {
      key: "changePasswd",
      text: "修改密码",
      link: "/user/changepd",
    },
    // {
    //   key: "member",
    //   text: "会员信息",
    //   link: "/memberInfo",
    // },
  ];

  return (
    <Header className={top ? 'navbar' : 'navbar small'}>
        <Link to="/">
          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img src="/favicon.ico" alt="Logo" style={{height: 50, marginRight: 5}}/>
            <img src="/name.png" alt="Logo" style={{width: 150}}/>
            {/*<Paragraph style={{color: "white", marginBottom: 0, fontSize: 20, fontWeight: "blod"}}>华青科技</Paragraph>*/}
          </div>
        </Link>
      <Menu
          theme="dark"
        mode="horizontal"
          className="navbar-menu"
        selectedKeys={menuOpenKeys}
        onOpenChange={handleMenuOpenChange}
      >
        {menuItems.map((item) => (
            item.subMenu ?
                (<Dropdown overlay={
                  <Menu>
                    {item.subMenu.map((subItem) => (
                      <Menu.Item className="navbar-menu-dropdown-item" key={subItem.key}>
                        <Link to={subItem.link}>{subItem.text}</Link>
                      </Menu.Item>
                    ))}
                  </Menu>
                } placement="bottomLeft">
                    <Menu.Item className="navbar-menu-item" key={item.key}>
                      <Link to="#">{item.text}</Link>
                    </Menu.Item>
                </Dropdown>) : (
                <Menu.Item className="navbar-menu-item" key={item.key}>
                    <Link to={item.link}>{item.text}</Link>
                </Menu.Item>
            )
        ))}
      </Menu>
      {/*{isLogin ? (*/}
      {/*    <div className="userinfo">*/}
      {/*      <Dropdown overlay={*/}
      {/*            <Menu>*/}
      {/*              {userMenu.map((Item) => (*/}
      {/*                <Menu.Item className="navbar-menu-dropdown-item" key={Item.key}>*/}
      {/*                  <Link to={Item.link}>{Item.text}</Link>*/}
      {/*                </Menu.Item>*/}
      {/*              ))}*/}
      {/*            </Menu>*/}
      {/*          } placement="bottomLeft">*/}
      {/*              <p style={{marginRight: "8px", paddingLeft: "3px", borderLeft: "1px solid #7a7a7a", userSelect: "none"}}>{nickName}，欢迎您！</p>*/}
      {/*      </Dropdown>*/}
      {/*      <Button icon={<LogoutOutlined />} onClick={() => handleLogout()}>*/}
      {/*        登出*/}
      {/*      </Button>*/}
      {/*    </div>*/}
      {/*) : (*/}
      {/*    <Button type="primary" icon={<UserOutlined />} onClick={() => navigate("/user/login")}>*/}
      {/*        登录*/}
      {/*    </Button>*/}
      {/*    )}*/}
    </Header>
  );
};

export default Navbar;
