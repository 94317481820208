import { useState } from "react";
import { Form, Input, Button, Modal } from "antd";
import { UserOutlined } from '@ant-design/icons';


export default function ForgetPassword () {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const [successVisible, setSuccessVisible] = useState(false);

    const handleSuccessOk = () => {
        setSuccessVisible(false);
        form.resetFields();
    };

    const handleForget = (values) => {
        setSubmitting(true);
        console.log('Received values of form: ', values);
        // 处理找回密码逻辑
        // 发送邮件等操作，此处省略
        setSuccessVisible(true);
        setSubmitting(false);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form form={form} onFinish={handleForget}>
            <Form.Item name="email" rules={[{ required: true, message: '请输入您的邮箱' }]}>
              <Input prefix={<UserOutlined />} placeholder="请输入要找回密码的注册邮箱" />
            </Form.Item>
            <Button type="primary" htmlType="找回密码" block loading={submitting}>
              找回密码
            </Button>
            <Modal
            title="新密码已发送"
            visible={successVisible}
            onOk={handleSuccessOk}
            onCancel={handleSuccessOk}
          >
            新密码已发送到您的邮箱，请尽快修改密码。
          </Modal>
          </Form>
    )
}