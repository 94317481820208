import React from "react"
import { Layout, Button, Typography, Col, Row } from 'antd';
import { Link } from 'react-router-dom';

import "./index.css"

const { Footer } = Layout;
const { Paragraph } = Typography;

const MyFooter = () => {
  return (
    <Footer style={{ backgroundColor: '#011c37', color: 'white'}}>
      <Row>
        <Col span={24} style={{ display: 'flex', justifyContent: 'center'}}>
          <Paragraph style={{color: "white", fontSize: "20px",  marginRight: "20px"}}>
            <Col span={24} style={{marginTop: "10px"}} >
              <img src="/logo512.png" alt="横琴华青科技Logo" title="横琴华青科技" style={{height: 100}}/>
            </Col>
          </Paragraph>
          {/*<img src="/path/to/company/logo" alt="Company Logo" style={{ height: 50, marginRight: 50 }} />*/}
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: 30}}>
            <Link to='/solution/chatbot'>
              <Button type="text" className="footer-text">智能对话系统</Button>
            </Link>
            <Link to='/service/assistant'>
              <Button type="text" className="footer-text">AI智能助手</Button>
            </Link>
            <Link to='/service/media'>
              <Button type="text" className="footer-text">视频文字处理</Button>
            </Link>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginRight: 30}}>
            <Link to='/solution/chatbot'>
              <Button type="text" className="footer-text">对话机器人</Button>
            </Link>
            <Link to='/solution/develop'>
              <Button type="text" className="footer-text">定制化开发</Button>
            </Link>
            <Link to='/solution/deploy'>
              <Button type="text" className="footer-text">多样化部署 </Button>
            </Link>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginRight: 30}}>
            <Link to='/about'>
              <Button type="text" className="footer-text" style={{ marginBottom: 20 }}>关于我们</Button>
            </Link>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', marginRight: 30}}>
            <div style={{ marginBottom: 8 }}>营业时间：周一至周五 9:00-18:00</div>
            <div style={{ marginBottom: 8 }}>联系方式：0756-8992958</div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column',justifyContent: "center"}}>
            <Paragraph style={{color: "white", display: "flex", justifyContent: "center", fontSize: "14px", marginBottom: 5}}>微信公众号</Paragraph>
            <img src="/qrcode.jpg" alt="qrcode" style={{height: 100}} />
          </div>
        </Col>
        <Col span={24} align="middle">
          <p style={{fontSize: "14px", color: "gray"}}>珠海横琴华青科技有限公司 | <a style={{fontSize: "14px", color: "gray"}} href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023074530号-2</a></p>
        </Col>
      </Row>
    </Footer>
  );
};

export default MyFooter;
