import React, {useEffect} from 'react';
import { Layout, Typography, Col, Row, Card } from 'antd';

import "./index.css"

const { Title, Paragraph } = Typography;


const keyPoints = [
  {
    title: '激情与创新',
    description: '敢于创新，勇于尝试，突破自我',
      img: "/creative.jpg"
  },
  {
    title: '团队合作',
    description: '相互合作、共同成长，充分发挥个人优势',
      img: "/team.jpg"
  },
  {
    title: '学习与发展',
    description: '持续学习，探索前沿技术',
    img: "/research.jpg"
  },
  {
    title: '开放与交流',
    description: '开放交流，共同探讨，充分发挥个人潜力',
      img: "/conversation.jpg"
  },
];

const AboutPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className="container">
        <div style={{ display: 'flex', alignItems: 'center', height: '350px', overflow: 'hidden'}}>
          <img
            src="/about.jpg"
            alt="About us"
            style={{width: '100%', height: 'auto', objectFit: 'cover'}}
          />
        </div>
        <Row className="about-row row-background-white" >
            <Col span={24} style={{ textAlign: 'center', marginBottom: '30px' }}>
                <Title level={2} className="about-title">关于我们</Title>
            </Col>
            <Col xs={24} sm={24} sm={24} lg={18} >
                <Title level={3} className="about-title">公司介绍</Title>
                  <Paragraph className="about-paragraph">
珠海横琴华青科技有限公司成立于2023年，是一家专注于自然语言处理技术的科技公司，致力于为客户提供智能对话系统、机器翻译、视频文字处理等解决方案，以AI技术为企业赋能。

我们的团队对自然语言处理技术和人工智能技术有丰富的实践经验，我们的宗旨是利用人工智能技术，以简洁易用的核心理念，为用户提供自然、高效、智能的体验。<br/><br/>

在不断追求技术突破和创新的过程中，我们始终坚持以用户需求为出发点，不断优化产品体验，为客户提供更优质、更可靠的技术和服务。我们相信，我们的技术和服务将带给客户更大的价值和贡献，助力客户在数字化时代实现商业成功，成为客户信赖的长期合作伙伴。

                  </Paragraph>
            </Col>
            <Col xs={24} sm={24} sm={24} lg={6} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <img src="/logo512-black.png" alt="Logo" style={{height: 200}}/>
            </Col>
        </Row>
        <Row justify="center" className="about-row row-background-gray">
            <Col style={{ textAlign: 'center' }}>
                <Title level={2}>愿景</Title>
                  <Paragraph className="about-paragraph">
                      我们致力于实现技术和商业的完美融合，通过自然语言处理技术和人工智能产品的不断创新，为客户提供更加智能、高效、个性化的产品和服务，助力企业在数字化时代实现转型，成为全球客户信赖的长期合作伙伴。
                  </Paragraph>
            </Col>
        </Row>
        <Row justify="center" className="about-row row-background-white">
            <Row style={{marginBottom: "50px"}}>
                <Col>
                  <Title level={2} className="about-title">企业文化</Title>
                </Col>
            </Row>
           <Row justify="center" gutter={[16, 16]}>
                {keyPoints.map((point, index) => (
                  <Col xs={24} sm={24} md={12} lg={6} key={index}>
                    <Card
                      hoverable
                      className="about-card"
                      cover={<img src={point.img} style={{ fontSize: '40px', color: '#1890ff', marginBottom: "30px", height: "80px" }} />}
                    >
                      <Card.Meta title={point.title} description={point.description} style={{ textAlign: "center", height: "100px"}}/>
                    </Card>
                  </Col>
                ))}
            </Row>
        </Row>
        {/*<Row justify="center" className="row">*/}
        {/*    <Title level={2}>合作伙伴</Title>*/}

        {/*</Row>*/}
    </div>
  );
};

export default AboutPage;
