import { useState } from 'react';
import QRCode from 'qrcode.react';

export default function QRLogin() {
  const [qrCodeData, setQRCodeData] = useState('');

  const handleQRCodeLoad = (dataUrl) => {
    setQRCodeData(dataUrl);
  };

  return (
    <div>
      <h2>微信扫码登录</h2>
      {qrCodeData ? (
        <img src={qrCodeData} alt="QR Code" />
      ) : (
        <div>二维码加载中...</div>
      )}
      <QRCode value="" onLoad={handleQRCodeLoad} />
    </div>
  );
}
