import {React, useState} from "react";
import {useNavigate } from "react-router-dom";
import { Form, Input, Button, Row, Col, Card, message } from 'antd';
import axios from 'axios';
import { isEqual } from 'lodash';

import CheckLogin from "../../../components/CheckLogin";
import Logout from "../../../components/Logout";
import "./index.css"

function UserProfile() {
  const navigate = useNavigate();

  const isLogin = CheckLogin();
  if (!isLogin) {
      navigate("/user/login");
  }
  const [isSubmitting, setIsSubmitting] = useState(false);
  const email = localStorage.getItem("email") || sessionStorage.getItem("email");
  const Id = localStorage.getItem("userId") || sessionStorage.getItem("userId");
  const passwd = localStorage.getItem("password") || sessionStorage.getItem("password");
  const nickname = localStorage.getItem("nickname") || sessionStorage.getItem("nickname");
  const wechat = localStorage.getItem("wechat") || sessionStorage.getItem("wechat");
  const [userInfo, setUserInfo] = useState({email, nickname, wechat});

  // 定义表单提交事件处理函数
  const handleSubmit = (values) => {
    setIsSubmitting(true);
    if (isEqual(values, userInfo)) {
        message.error("您的信息没有变化，不需要更新哦！")
    } else {
        axios.post('/huaqing_back/updateUserProfile', {...values, Id, passwd}).then((response) => {
            const data = response.data;
            if (data.success) {
                setUserInfo(values);
                const isAutoLogin = localStorage.getItem("autoLogin");
                if (isAutoLogin) {
                    localStorage.setItem('wechat', values.wechat);
                    localStorage.setItem('nickname', values.nickname);
                } else {
                    sessionStorage.setItem('wechat', values.wechat);
                    sessionStorage.setItem('nickname', values.nickname);
                }
                window.location.reload();
                message.success(data.message);
            } else {
                Logout();
                message.error(data.message);
            }
        }).catch((error) => {
            message.error("更新用户信息失败，请稍后再试。")

        })
    }
    setIsSubmitting(false);
  };

  return (
    <Row className="user-row">
        <Col align="middle" span={24} >
            <Card className="user-container">
                <h1>个人信息</h1>
                  <Form onFinish={handleSubmit} initialValues={userInfo}>
                    <Form.Item
                      label="邮箱"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: '请输入邮箱!',
                        },
                          {
                              type: "email",
                              message: "请输入正确格式的邮箱！"
                          }
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>

                    <Form.Item
                      label="昵称"
                      name="nickname"
                      rules={[
                        {
                          required: true,
                          message: '请输入一个昵称!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="微信号"
                      name="wechat"
                      rules={[
                        {
                          required: true,
                          message: '请输入您的微信号!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item >
                      <Button type="primary" htmlType="submit" loading={isSubmitting}>
                        Save
                      </Button>
                    </Form.Item>
                  </Form>
            </Card>
        </Col>
    </Row>
  );
}

export default UserProfile;
