import React from "react";

export default function Logout () {
    localStorage.removeItem("LoginStatus")
    localStorage.removeItem('userId');
    localStorage.removeItem('nickname');
    localStorage.removeItem('email');
    localStorage.removeItem('password');
    localStorage.removeItem('autoLogin');
    localStorage.removeItem('loginExpiresAt');
    sessionStorage.removeItem("LoginStatus");
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('nickname');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('password');
    sessionStorage.removeItem('autoLogin');
    sessionStorage.removeItem('loginExpiresAt');
}