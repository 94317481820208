import React, {useState} from 'react';

const DialogPage = () => {
    return (
    <div className="container">

    </div>
  );
};

export default DialogPage;