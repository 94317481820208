import React, { useState, useEffect } from "react";
import {useNavigate } from "react-router-dom";
import { Form, Input, Button, message, Row, Col, Card } from "antd";
import axios from 'axios';

import checkLogin from "../../../components/CheckLogin";
import "./index.css"

const ChangePassword = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

  const onFinish = (values) => {
    setIsLoading(true);
    try {
      const { oldPassword, newPassword } = values;
      const userId = localStorage.getItem("userId") || sessionStorage.getItem("userId");

      if (userId === null) {
          message.error("未登录，请先登录！")
      } else {
          const params = {
              "id": userId,
              "old_password": oldPassword,
              "new_password": newPassword,
          }
          axios.post('/huaqing_back/changepd', params)
              .then((response) => {
                  const data = response.data;
                  if (data.success) {
                      message.success(data.message);
                  } else {
                      message.error(data.message);
                  }
              }).catch((error) => {
              message.error('Change password failed: ' + error);
          });
      }
    } catch (error) {
      message.error('Error during changing password: '+error);
    }
    setIsLoading(false);
  };

    useEffect(() => {
        const LoginStatus = checkLogin();
        if (!LoginStatus) {
            navigate("/user/login");
        }
    }, []);

  return (
      <Row className="login-row">
        <Col align="middle" span={24}>
            <Card className="login-container">
                <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                initialValues={{
                  oldPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                }}
              >
                <Form.Item
                  label="旧密码"
                  name="oldPassword"
                  rules={[{ required: true, message: "请输入旧密码！" }]}
                >
                  <Input.Password placeholder="请输入旧密码" />
                </Form.Item>

                <Form.Item
                  label="新密码"
                  name="newPassword"
                  rules={[
                    { required: true, message: "请输入新密码！" },
                    { min: 6, message: "密码长度至少6位！" },
                  ]}
                >
                  <Input.Password placeholder="请输入新密码" />
                </Form.Item>

                <Form.Item
                  label="确认新密码"
                  name="confirmPassword"
                  rules={[
                    { required: true, message: "请确认新密码！" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newPassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("两次密码输入不一致！");
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="请确认新密码" />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    修改密码
                  </Button>
                </Form.Item>
              </Form>
            </Card>
        </Col>
    </Row>
  );
};

export default ChangePassword;
