import React from "react";

export default function checkLogin() {
  const savedAutoLogin = localStorage.getItem('autoLogin') === 'true';
    if (savedAutoLogin) {
      const loginExpiresAt = localStorage.getItem('loginExpiresAt');
      const LoginStatus = localStorage.getItem("LoginStatus") === "true";
      if (LoginStatus && Date.now() < loginExpiresAt) {
        return true;
      } else {
        localStorage.removeItem("LoginStatus");
        localStorage.removeItem('userId');
        localStorage.removeItem('nickname');
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        localStorage.removeItem('autoLogin');
        localStorage.removeItem('loginExpiresAt');
        return false;
      }
    } else {
      const LoginStatus = sessionStorage.getItem("LoginStatus") === "true";
      if (LoginStatus) {
        return true;
      } else {
          return false;
      }
    }
}