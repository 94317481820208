import React, {useState, useEffect} from 'react';
import { Button, Row, Typography, Col, Card, Modal, Form, Input, message  } from "antd";
import axios from "axios";

import "./deploy.css"

const { Title, Paragraph } = Typography;

const DeploySolutionPage = () => {
    const [visible, setVisible] = useState(false);

    const handleButtonClick = () => {
        setVisible(true);
    };

    const onFinish = (values) => {
    const params = {
        "function": "项目部署咨询",
        ...values
    };

    axios.post("/huaqing_back/consult", params).then((response)=>{
        const data = response.data;
        if (data.success) {
            message.success(data.message);
        } else {
            message.error(data.message);
        }
    })
    setVisible(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
        <Modal
          title="项目部署咨询"
          visible={visible}
          onCancel={() => setVisible(false)}
          footer={null}
        >
          <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
            >
              <Form.Item
                label="姓名"
                name="name"
                rules={[{ required: true, message: '请输入您的姓名！' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="微信"
                name="wechat"
                rules={[{ required: true, message: '请输入您的微信号！' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="邮箱"
                name="wechat"
                rules={[{ required: true, message: '请输入您的邮箱！' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="业务需求"
                name="experience"
                rules={[
                  { required: true, message: '请输入您的业务需求！' },
                  { max: 100, message: '只需要简单输入要点，最好不要超过100个字哦！' },
                ]}
              >
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  提交
                </Button>
              </Form.Item>
            </Form>
        </Modal>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", height: '350px',  overflow: 'hidden', backgroundImage: "url(/deploy-poster.jpg) ",  backgroundSize: 'cover'}}>
                <Row justify="center">
                    <Col span={24} align="middle">
                        <Title level={2} style={{color: "white"}}>多样化部署</Title>
                        <Paragraph style={{fontSize: "18px", color: "white"}}>
                            提供模型私有化部署及云部署方案，帮助企业灵活部署服务，降低运维成本。
                        </Paragraph>
                    </Col>
                    <Col span={24} align="middle">
                        <Button type="primary" size="large" onClick={handleButtonClick}>
                            合作咨询
                        </Button>
                    </Col>
                </Row>
        </div>
        <Row justify="center" className="row row-background-white" >
            <Col span={24} className="title">
                <Title level={2}>功能齐全的部署方案</Title>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} align="center">
                <Col></Col>
            </Col>
        </Row>
        <Row justify="center" className="row row-background-gray" style={{paddingLeft: "20%", paddingRight: "20%"}}>
            <Col span={24} >
                <Title level={2} className="title">服务优势</Title>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} align="center" className="col">
                <Row justify="start">
                    <Col span={8} align="center">
                        <Card className="custom-card">
                            <img src="/deploy/team.png" className="deploy-icon-img"/>
                        </Card>
                    </Col>
                    <Col span={16} align="left">
                        <Title level={3}>专业技术团队，针对需求提供方案</Title>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} align="center" className="col">
                <Row justify="start">
                    <Col span={8} align="center">
                        <Card className="custom-card">
                            <img src="/deploy/24hours.png" className="deploy-icon-img"/>
                        </Card>
                    </Col>
                    <Col span={16} align="left">
                        <Title level={3}>专属服务咨询，全天候服务保障</Title>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} align="center" className="col">
                <Row justify="start">
                    <Col span={8} align="center">
                        <Card className="custom-card">
                            <img src="/deploy/deploy.png" className="deploy-icon-img"/>
                        </Card>
                    </Col>
                    <Col span={16} align="left">
                        <Title level={3}>离、在线快速部署，实现数据互通</Title>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} align="center" className="col">
                <Row justify="start">
                    <Col span={8} align="center">
                        <Card className="custom-card">
                            <img src="/deploy/maintain.png" className="deploy-icon-img"/>
                        </Card>
                    </Col>
                    <Col span={16} align="left">
                        <Title level={3}>企业按需开发或定期迭代</Title>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>
  );
};

export default DeploySolutionPage;
