import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Layout } from 'antd';
import Header from './components/Header';
import Footer from './components/Footer'
import HomePage from './pages/HomePage';
import SolutionPage from './pages/SolutionPage';
import AboutPage from './pages/AboutPage';
import UserPage from "./pages/UserPage";
import ServicePage from "./pages/ServicePages";

import "./App.css"
const { Content } = Layout;

function App() {
  const [activePage, setActivePage] = useState('home');

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleNavigate = (page) => {
    setActivePage(page);
  };

  const handleLanguageChange = (event) => {
    // 处理语言切换事件
  };
  

  return (
    <Router>
      <Layout style={{ minHeight: '100vh'}}>
        <Header />
        <Content style={{backgroundColor: "#112b3c"}}>
          <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/service/*" element={<ServicePage/>} />
            <Route path="/solution/*" element={<SolutionPage/>} />
            <Route path="/about" element={<AboutPage/>} />
            <Route path="/user/*" element={<UserPage/>} />
          </Routes>
        </Content>
        <Footer/>
      </Layout>
    </Router>
  );
}

export default App;
