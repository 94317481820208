import React  from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import DialogPage from './DialogPage';
import AssistantPage from './AssistantPage';
import MediaPage from './MediaPage';

const ServicePage = () => {
    return (
        <div>
          <Routes>
            <Route path="*" element={<Navigate to="assistant"/>} />
            <Route path="dialog" element={<DialogPage/>} />
            <Route path="assistant" element={<AssistantPage/>} />
            <Route path="media" element={<MediaPage/>} />
          </Routes>
        </div>
    );
};

export default ServicePage;
