import React, {useState, useEffect} from 'react';
import {Button, Row, Typography, Col, Card, Modal, Form, Input, message, Select, Upload, Empty } from "antd";
import { Link } from 'react-router-dom';
import axios from "axios";

import "./index.css";
const { Title, Paragraph } = Typography;
const { Option } = Select;

const MediaPage = () => {
    const [visible, setVisible] = useState(false);
    const [mediaTool, setMediaTool] = useState('video_clip');
    const [mediaLimit, setMediaLimit] = useState({
        "video_clip": 2,
        "subtitle": 2,
        "extract": 1,
        "recognize": 2,
    });
    const handleUpload = ({ file }) => {
        const formData = new FormData();
        formData.append('file', file);
        axios.post('/huaqing_back/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
        }
        }).then(res => {
            console.log(res.data);
        }).catch(err => {
            console.log(err);
        });
    };

    const handleToolChange = (value) => {
        setMediaTool(() => value);
      }

    const handleButtonClick = () => {
        setVisible(true);
    };

    const onFinish = (values) => {
        console.log('Received values of form:', values);
        setVisible(false);
        message.success('提交成功！');
      };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
    <div className="container">
        <Modal
          title="项目合作"
          visible={visible}
          onCancel={() => setVisible(false)}
          footer={null}
        >
          <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
            >
              <Form.Item
                label="姓名"
                name="name"
                rules={[{ required: true, message: '请输入您的姓名！' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="微信"
                name="wechat"
                rules={[{ required: true, message: '请输入您的微信号！' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="邮箱"
                name="email"
                rules={[{ required: true, message: '请输入您的邮箱！' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="合作需求"
                name="experience"
                rules={[
                  { required: true, message: '请输入您想要合作的内容！' },
                  { max: 100, message: '体验场景不能超过100个字符！' },
                ]}
              >
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  提交
                </Button>
              </Form.Item>
            </Form>
        </Modal>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", height: '350px', overflow: 'hidden', backgroundImage: "url(/media.jpg) ",  backgroundSize: 'cover'}}>
                <Row justify="center">
                    <Col span={24} align="middle">
                        <Title level={2} style={{color: "white"}}>视频文字处理</Title>
                        <Paragraph style={{fontSize: "18px", color: "white"}}>
                            利用语音、图像技术，辅助快速处理录播视频，提高工作效率。
                        </Paragraph>
                        <Button type="primary" onClick={handleButtonClick}>
                            合作咨询
                        </Button>
                    </Col>
                </Row>
        </div>
        <Row className="row row-background-gray">
            <Col span={24} align="middle" className="title">
                <Title level={2}>服务功能</Title>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} className="media-col" align="middle">
                <Card className="media-card" bordered={false}  bodyStyle={{height: "100%"}} style={{height: "250px"}}>
                    <img src="/subtitle.png" alt="多语言字幕" style={{  width: '25%' }} />
                    <Title level={4}>多语言字幕</Title>
                    <Paragraph>提供多语言字幕嵌入，满足不同客户需求。</Paragraph>
                </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}  align="middle" className="media-col">
                <Card className="media-card" bordered={false} bodyStyle={{height: "100%"}} style={{height: "250px"}}>
                    <img src="/clip.png" alt="自动剪辑" style={{  width: '25%' }} />
                    <Title level={4}>自动剪辑</Title>
                    <Paragraph>筛除视频中空白、无用片段，使视频内容更流畅。</Paragraph>
                </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}  align="middle" className="media-col">
                <Card className="media-card" bordered={false} bodyStyle={{height: "100%"}} style={{height: "250px"}}>
                    <img src="/audio.png" alt="音频分离" style={{  width: '25%' }} />
                    <Title level={4}>音频分离</Title>
                    <Paragraph>提取视频中的音频，分离人声和背景音。</Paragraph>
                </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}  align="middle" className="media-col">
                <Card className="media-card" bordered={false} bodyStyle={{height: "100%"}} style={{height: "250px"}}>
                    <img src="/voice_recog.png" alt="语音识别" style={{  width: '25%' }} />
                    <Title level={4}>语音识别</Title>
                    <Paragraph>精准识别语音，转换为多语言文字。</Paragraph>
                </Card>
            </Col>
        </Row>
        <Row className="row row-background-white" style={{paddingLeft: "20%", paddingRight: "20%"}}>
            <Col span={24} align="middle" className="title">
                <Title level={2}>产品体验</Title>
            </Col>
            <Col span={24} className="media-col" style>
                <Empty description="功能开发中，敬请期待~"/>
                {/*<Select*/}
                {/*  showSearch*/}
                {/*  defaultValue={mediaTool}*/}
                {/*  style={{ width: 200 }}*/}
                {/*  placeholder="请选择工具"*/}
                {/*  optionFilterProp="children"*/}
                {/*  onChange={handleToolChange}*/}
                {/*  filterOption={(input, option) =>*/}
                {/*    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0*/}
                {/*  }*/}
                {/*>*/}
                {/*  <Option value="video_clip">自动剪辑</Option>*/}
                {/*  <Option value="subtitle">字幕生成</Option>*/}
                {/*    <Option value="extract">声音抽取</Option>*/}
                {/*  <Option value="recognize">语音识别</Option>*/}
                {/*</Select>*/}
                {/*<p>剩余体验次数：{mediaLimit[mediaTool]}次</p>*/}

            </Col>
            {/*<Col span={24} className="media-col">*/}
            {/*    <Upload*/}
            {/*        customRequest={handleUpload}*/}
            {/*        accept="audio/*,video/*"*/}
            {/*        maxCount={1}*/}
            {/*        style={{width: "50px"}}*/}
            {/*      >*/}
            {/*        <Button>上传文件</Button>*/}
            {/*      </Upload>*/}
            {/*</Col>*/}
        </Row>
        <Row className="row row-background-gray" justify="center">
            <Col span={24} align="middle" className="title">
                <Title level={2}>合作单位</Title>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}   align="middle" className="media-col">
                <Link to={"http://zhyjpx.com/"}>
                    <Card className="media-card" hoverable bodyStyle={{height: "100%"}} style={{height: "100px"}}>
                        <img src="/yujia.jpg" alt="珠海市御伽职业培训学校" style={{  width: '100%' }} />
                    </Card>
                </Link>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}  align="middle" className="media-col">
                <Card className="media-card" hoverable bodyStyle={{height: "100%"}} style={{height: "100px"}}>
                    <img src="/langjing.png" alt="珠海市朗京文化传播" style={{  width: '100%' }} />
                </Card>
            </Col>
        </Row>
    </div>
  );
};

export default MediaPage;