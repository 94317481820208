import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import LoginPage from './LoginPage';
import UserInfoPage from './UserinfoPage';
import ChangePasswdPage from './ChangePasswdPage';

const UserPage = () => {
    return (
        <div>
          <Routes>
            <Route path="*" element={<Navigate to="login"/>} />
            <Route path="login" element={<LoginPage/>} />
            <Route path="userinfo" element={<UserInfoPage/>} />
            <Route path="changepd" element={<ChangePasswdPage/>} />
          </Routes>
        </div>
    );
};

export default UserPage;
