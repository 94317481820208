import React, {useState, useEffect} from 'react';
import { Button, Row, Typography, Col, Card, Modal, Form, Input, message, Empty} from "antd";
import { useNavigate  } from "react-router-dom";

import CheckLogin from "../../../components/CheckLogin";
import "./index.css"

const { Title, Paragraph } = Typography;

const ChatbotSolutionPage = () => {
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();

    const handleButtonClick = () => {
    // 检查用户是否已登录
        const isLoggedIn = CheckLogin;

        if (isLoggedIn) {
          setVisible(true);
        } else {
          // 如果未登录则跳转到登录页面
          navigate('/user/login')
        }
    };

    const onFinish = (values) => {
    console.log('Received values of form:', values);
    setVisible(false);
    message .success('提交成功！');
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
        <Modal
          title="对话机器人体验申请"
          visible={visible}
          onCancel={() => setVisible(false)}
          footer={null}
        >
          <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
            >
              <Form.Item
                label="姓名"
                name="name"
                rules={[{ required: true, message: '请输入您的姓名！' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="微信"
                name="wechat"
                rules={[{ required: true, message: '请输入您的微信号！' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="体验场景"
                name="experience"
                rules={[
                  { required: true, message: '请输入您的体验场景！' },
                  { max: 100, message: '体验场景不能超过100个字符！' },
                ]}
              >
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  提交
                </Button>
              </Form.Item>
            </Form>
            <p style={{ marginTop: 24 }}>
              提示：请结合自身业务设想一到两个场景，比如订机票、餐厅订位等。
            </p>
        </Modal>


        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", height: '350px', overflow: 'hidden', backgroundImage: "url(/chatbot-poster.jpg) ",  backgroundSize: 'cover'}}>
                <Row justify="center">
                    <Col span={24} align="middle">
                        <Title level={2} style={{color: "white"}}>智能对话机器人</Title>
                        <Paragraph style={{fontSize: "18px", color: "white"}}>
                            结合智能交互、语音分析技术，提高服务质量，提升用户体验。
                        </Paragraph>
                    </Col>
                    <Col span={24} align="middle">
                        <Button type="primary" size="large" onClick={handleButtonClick}>
                            申请体验
                        </Button>
                    </Col>
                </Row>
        </div>
        <Row  justify="center" className="row row-background-white" >
            <Col span={24}  className="title">
                <Title level={2}>产品特点</Title>
            </Col>
            <Col span={8} align="middle">
                <h2 className="chatbot-card-title">自主研发技术</h2>
                <p className="chatbot-card-text">保持前沿技术探索，基于自研技术进行研发，保证产品质量。</p>
            </Col>
            <Col span={8} align="middle">
                <h2 className="chatbot-card-title">可定制化开发</h2>
                <p className="chatbot-card-text">根据客户自身业务及需求，提供针对性解决方案。</p>
            </Col>
            <Col span={8} align="middle">
                <h2 className="chatbot-card-title">全天候服务</h2>
                <p className="chatbot-card-text">7 * 24小時提供智能服务，快速响应，提升效率上限。</p>
            </Col>
            <Col span={8} align="middle">
                <h2 className="chatbot-card-title">快速对接服务</h2>
                <p className="chatbot-card-text">可与企业无缝对接，提供在线、离线部署方案，满足不同场景需求。</p>
            </Col>
            <Col span={8} align="middle">
                <h2 className="chatbot-card-title">多种交互方式</h2>
                <p className="chatbot-card-text">利用自然语言理解、语音交互技术，提供多种交互方案供客户选择，有效提升用户体验。</p>
            </Col>
            <Col span={8} align="middle">
                <h2 className="chatbot-card-title">数据分析学习</h2>
                <p className="chatbot-card-text">提供可视化工具，分析对话数据及用户反馈，辅助运维人员智能优化模型。</p>
            </Col>
        </Row>

        <Row justify="center" className="row row-background-gray">
            <Col span={24} className="title">
                <Title level={2}>方案流程</Title>
            </Col>
            <Col span={24}>
                <img id="chatbot-flow" src="/chatbot-flow.jpg" alt="产品流程" />
            </Col>
        </Row>

        <Row  justify="center" className="row row-background-white" >
            <Col span={24} className="title">
                <Title level={2}>应用场景</Title>
            </Col>

            <Card style={{ borderRadius: 8, boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)', marginTop: "30px"}}>
                <Row align="middle" >
                    <Col xs={24} sm={24} md={12} lg={12} align="center">
                        <div >
                            <h2 className="chatbot-card-title" style={{textAlign: "left"}}>智能客服</h2>
                            <p className="chatbot-card-text" style={{textAlign: "left"}}>通过在线客服的方式为用户提供咨询、自助服务，大幅度提高应答效率，减轻人工客服的工作量。覆盖领域包括：零售业、银行和金融行业、网络服务提供商、旅游业、医疗行业等。</p>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} align="left">
                        <div>
                            <img className="chatbot-img" src="/chatbot/AI_custom.jpg" alt="AI智能客服"/>
                        </div>
                    </Col>
                </Row>
            </Card>
            <Card style={{ borderRadius: 8, boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)', marginTop: "30px" }}>
                <Row align="middle" justify="space-between">
                    <Col xs={24} sm={24} md={12} lg={12} align="center">
                        <div>
                            <img className="chatbot-img" src="/chatbot/Assistant.jpg" alt="AI智能助手"/>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} align="left">
                        <div>
                            <h2 className="chatbot-card-title">智能助手</h2>
                            <p className="chatbot-card-text">能够根据用户的需求和意图，快速回答问题、提供建议、完成任务等，从而帮助用户更好地完成工作和日常任务。基于AI技术提供的自学习能力，持续学习和适应用户的使用习惯，为用户提供更个性化、更精准的帮助和服务。</p>
                        </div>
                    </Col>
                </Row>
            </Card>
            <Card style={{ borderRadius: 8, boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)', marginTop: "30px" }}>
                <Row align="middle">
                    <Col xs={24} sm={24} md={12} lg={12} align="center">
                        <div>
                            <h2 className="chatbot-card-title" style={{textAlign: "left"}}>对话式交互</h2>
                            <p className="chatbot-card-text" style={{textAlign: "left"}}>基于自然语言处理技术和人工智能技术的全新交互方式，通过自然语言指令整合软件功能，使页面更简洁明了，用户体验更加友好高效。</p>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} align="left">
                        <div>
                            <img className="chatbot-img" src="/chatbot/interactive.jpg" alt="对话式软件交互"/>
                        </div>
                    </Col>
                </Row>
            </Card>
        </Row>
       <Row justify="center" className="row row-background-gray">
            <Col span={24} className="title">
                <Title level={2}>产品体验</Title>
            </Col>
            <Col span={24}>
                <Empty description="功能开发中，敬请期待~"/>
            </Col>
        </Row>
    </div>
  );
};

export default ChatbotSolutionPage;
