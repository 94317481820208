import React  from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import ChatbotSolution from './chatbot-solution';
import DeploySolution from './deploy-solution';
import DevelopSolution from './develop-solution';

const SolutionPage = () => {
    return (
        <div>
          <Routes>
            <Route path="*" element={<Navigate to="chatbot"/>} />
            <Route path="chatbot" element={<ChatbotSolution/>} />
            <Route path="deploy" element={<DeploySolution/>} />
            <Route path="develop" element={<DevelopSolution/>} />
          </Routes>
        </div>
    );
};

export default SolutionPage;
