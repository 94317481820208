import { useState, useEffect } from 'react';
import {useNavigate } from "react-router-dom";
import { Form, Input, Button, Col, Checkbox, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import axios from 'axios';


import Register from "../Register";
import ForgetPassword from "../ForgetPassword";

import "./index.css"


export default function Login(props) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [autoLogin, setAutoLogin] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const {isRegister, setIsRegister, isForgetpd, setIsForgetpd} = props;
  const [nickName, setNickname] = useState("");
  const [userId, setUserId] = useState(null);
  const [mail, setMail] = useState("");
  const [isLogin, setIsLogin] = useState(false);


  const onAutoLoginChange = (e) => {
    setAutoLogin(() => e.target.checked);
  };

  const handleRegisterClick = () => {
    setIsRegister(true);
  };

   const handleForgetpdClick = () => {
    setIsForgetpd(true);
  };

  function handleLogin (values) {
    // 这里可以添加登录逻辑
    setSubmitting(true);
    try {
      const { email, password } = values;
      const params = {
        "email": email,
        "password": password
      }

      axios.post('/huaqing_back/login', params)
      .then((response) => {
        const data = response.data;
        if (data.success) {
          // TODO: navigate to dashboard or other pages
          const userId = data.userInfo[0];
          const nickname = data.userInfo[2];

          if (autoLogin) {
            localStorage.setItem('autoLogin', true);
            localStorage.setItem('email', email);
            localStorage.setItem('password', password);
            localStorage.setItem('loginExpiresAt', Date.now() + 7 * 24 * 60 * 60 * 1000)
            localStorage.setItem('userId', userId);
            localStorage.setItem('nickname', nickname);
            localStorage.setItem("LoginStatus", true);
          } else {
            sessionStorage.setItem('email', email);
            sessionStorage.setItem('password', password);
            sessionStorage.setItem('userId', userId);
            sessionStorage.setItem('nickname', nickname);
            sessionStorage.setItem("LoginStatus", true);
          }
          setIsLogin(true);
          setNickname(nickname);
          setMail(email);
          setSubmitting(false);
          // 登录成功后跳转到上一个页面
          navigate("/");
        } else {
          message.error(data.message);
          setSubmitting(false);
        }
      })
      .catch((error) => {
        message.error('Login failed: ' + error);
      });
    } catch (error) {
      message.error('Error during login');
    }
  }

  useEffect(() => {
    const savedEmail = localStorage.getItem('email');
    const savedPassword = localStorage.getItem('password');
    const savedAutoLogin = localStorage.getItem('autoLogin') === 'true';
    const loginExpiresAt = localStorage.getItem('loginExpiresAt');

    if (!isLogin && savedEmail && savedPassword && savedAutoLogin && Date.now() < loginExpiresAt) {
      handleLogin({ email: savedEmail, password: savedPassword });
    }
  }, [])


  return (
      <div>
          {(isRegister && <Register/>)}
          {(isForgetpd && <ForgetPassword/>)}
          {
              (!isForgetpd && !isRegister &&
                  <div>
                      <Form form={form} onFinish={handleLogin}>
                        <Form.Item name="email" rules={[{ required: true, message: '请输入您的邮箱' }]}>
                          <Input prefix={<UserOutlined />} placeholder="邮箱" />
                        </Form.Item>
                        <Form.Item name="password" rules={[{ required: true, message: '请输入密码' }]}>
                          <Input.Password prefix={<LockOutlined />} placeholder="密码" />
                        </Form.Item>
                        <Form.Item className="auto-login">
                          <Checkbox onChange={onAutoLoginChange}>
                            自动登录
                          </Checkbox>
                        </Form.Item>
                        <Button type="primary" htmlType="登录" block loading={submitting}>
                          登录
                        </Button>
                      </Form>
                      <div className="separator"></div>
                      <Col align="middle" span={24}>
                          <Button type="link" block onClick={handleRegisterClick}>
                            注册
                          </Button>
                      </Col>
                      <Col align="middle" span={24}>
                          <Button type="link" block onClick={handleForgetpdClick}>
                            忘记密码
                          </Button>
                      </Col>
                  </div>
              )
          }
      </div>
  );
}
