import { useState } from 'react';
import { Form, Input, Button, Modal } from "antd";
import axios from 'axios';

import { UserOutlined, MailOutlined, WechatOutlined, LockOutlined } from '@ant-design/icons';


export default function Register () {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const [successVisible, setSuccessVisible] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false);

    const handleSuccessOk = () => {
        setSuccessVisible(false);
        form.resetFields();
      };

      const handleErrorOk = () => {
        setErrorVisible(false);
      };

      const handleRegister = (values) => {
        setSubmitting(true);
        const {email, nickname, wechat, password} = values;
        console.log(values)
        axios.post('/huaqing_back/register', {
          email: email,
          nickname: nickname,
          wechat: wechat,
          password: password,
        }).then(response => {
          const data = response.data;

          if (data.success) {
              setSuccessVisible(true);
            form.resetFields();
          } else {
              setErrorVisible(true);
          }
        }).catch(error => {
          // Handle any errors that occur
          console.error(error);
        });
        setSubmitting(false);
      };

      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    return (
        <Form form={form} onFinish={handleRegister}>
            <Form.Item name="email" rules={[
                { required: true, message: '请输入您的邮箱' },
                { type: "email", message: "请输入正确的邮箱地址" },

            ]}>
              <Input prefix={<MailOutlined />} placeholder="邮箱" />
            </Form.Item>
            <Form.Item
                name="nickname"
                rules={[
                  {
                    required: true,
                    message: '请输入您的昵称',
                  },
                ]}
              >
                <Input prefix={<UserOutlined />} placeholder="昵称"/>
            </Form.Item>
            <Form.Item
                name="wechat"
                rules={[
                  {
                    required: true,
                    message: '请输入您的微信号',
                  },
                ]}
              >
                <Input prefix={<WechatOutlined />} placeholder="微信号"/>
              </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: '请输入您的密码',
                      },
                        { min: 6, message: "密码长度至少6位！" }
                    ]}
                >
                    <Input.Password prefix={<LockOutlined />} placeholder="密码"/>
                  </Form.Item>
                  <Form.Item
                    name="confirmPassword"
                    rules={[
                      {
                        required: true,
                        message: '请再次输入您的密码',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('两次输入的密码不一致'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password prefix={<LockOutlined />} placeholder="确认密码"/>
                  </Form.Item>
            <Button type="primary" htmlType="注册" block loading={submitting}>
              注册
            </Button>
            <Modal
                title="注册成功"
                visible={successVisible}
                onOk={handleSuccessOk}
                onCancel={handleSuccessOk}
            >
                恭喜您，注册成功！
            </Modal>
            <Modal
                title="注册失败"
                visible={errorVisible}
                onOk={handleErrorOk}
                onCancel={handleErrorOk}
            >
                对不起，该邮箱已被注册，请尝试使用其他邮箱注册。
            </Modal>
          </Form>
    )
}